<template>
  <div class="contentValidationCommerciauxTh">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select w-46-px"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  w-46-px"
            v-model="filterDepot"
            :options="[{ value: null, text: 'Tous' }, ...computedDepot]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Type</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterType"
            :options="[{ value: null, text: 'tous' }, ...ComputedgetAllType]"
            value-field="value"
            text-field="text"
            @change="handleChangeType"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Utilisateur</span>
          </div>

          <SelectComponent
            :options="ComputedgetAllEmployee"
            :value="filterUser"
            label="full_name"
            champName="utilisateur"
            filterName="filterUser"
            :change="handleChange"
            :track-by="'full_name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue"
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style   w-46-px">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterValidation"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>
        <div v-if="getChargementPaieIndependantTh" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="body-box-rapport"
      :class="{ 'resizing-body-box-rappot': computeAdmin }"
    >
      <palatte-color />
      <div class="table-rapport-style w-100-p">
        <b-table
          responsive
          show-empty
          id="my-table"
          class="custom-table-style tableValidationCommerciauxTh  table-header "
          :items="computedgetuserSalary"
          :fields="computedFiled"
          bordered
          sticky-header
          hover
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(statue)="data">
            <EditableInput
              :editable="checkPermission($options.name)"
              champName="statue"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue"
              :updateFunction="validationSalaryTh"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(details)="data">
            <button class="btn" @click="data.toggleDetails">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(name)="data">
            {{ data.item.employee }}
          </template>
          <template v-slot:cell(payed_at)="data">
            <EditableInput
              :editable="checkPermission($options.name)"
              champName="payed_at"
              :item="data.item"
              :value="data.item.payed_at"
              type="datetime"
              editableType="datetime"
              :updateFunction="validationSalaryTh"
            />
          </template>
          <template v-slot:cell(type)="data">
            {{ data.item.type }}
          </template>
          <template v-slot:cell(month)="data">
            {{ data.item.month }}
          </template>
          <template v-slot:cell(acompt)="data">
            {{ data.item.acompt }}
          </template>

          <template v-slot:cell(IGD)="data">
            {{ data.item.IGD }}
          </template>
          <template v-slot:cell(additional_prime)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="additional_prime"
                :item="data.item"
                :year="filteryear"
                :value="data.item.additional_prime"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.additional_prime }}</template>
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }}
          </template>
          <template v-slot:cell(previsite)="data">
            {{ data.item.previsite }}
          </template>
          <template v-slot:cell(vente)="data">
            {{ data.item.vente }}
          </template>
          <template v-slot:cell(interne)="data">
            {{ data.item.interne }}
          </template>
          <template v-slot:cell(externe)="data">
            {{ data.item.externe }}
          </template>
          <template v-slot:cell(variable_vmc)="data">
            {{ data.item.vmc }}
          </template>
          <template v-slot:cell(variable_pac)="data">
            {{ data.item.pac }}
          </template>
          <template v-slot:cell(fix)="data">
            {{ data.item.fix }}
          </template>
          <template v-slot:cell(prime_qualite)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="prime_qualite"
                :item="data.item"
                :year="filteryear"
                :value="data.item.prime_qualite"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.prime_qualite }}</template>
          </template>
          <template v-slot:cell(prime_objectif)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="prime_objectif"
                :item="data.item"
                :year="filteryear"
                :value="data.item.prime_objectif"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.prime_objectif }}</template>
          </template>
          <template v-slot:cell(prime_sav)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="prime_sav"
                :item="data.item"
                :year="filteryear"
                :value="data.item.prime_sav"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
            /></template>
            <template v-else>{{ data.item.prime_sav }}</template>
          </template>
          <template v-slot:cell(other_prime_name)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="other_prime_name"
                :item="data.item"
                :year="filteryear"
                type="text"
                :value="data.item.other_prime_name"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
              />
            </template>
            <template v-else>{{ data.item.other_prime_name }}</template>
          </template>
          <template v-slot:cell(other_prime_value)="data">
            <template
              v-if="computeReponsableDepot && computedTypeValidation(data.item)"
            >
              <editable-input
                champName="other_prime_value"
                :item="data.item"
                :year="filteryear"
                :value="data.item.other_prime_value"
                :depot="filterDepot"
                :updateFunction="updatePrimeTh"
                className="editable-input-fiche"
                :defaultColor="true"
            /></template>

            <template v-else>{{ data.item.other_prime_value }}</template>
          </template>
          <template v-slot:cell(deducation)="data">
            {{ data.item.deducation }}
          </template>

          <template v-slot:cell(comment)="data">
            <commentInput
              v-if="!computeAdmin"
              :item="data.item"
              :value="data.item.comment"
              :updateFunction="newCommentTh"
            />
            <span v-else>{{ data.item.comment }}</span>
          </template>
          <template v-slot:cell(facture_uploaded)="data">
            <b-button
              v-if="
                (data.item.statue == 'encore de paiement' ||
                  data.item.statue == 'facture valid' ||
                  data.item.statue == 'facture refuse' ||
                  data.item.statue == 'paye' ||
                  data.item.statue == 'encore de verification') &&
                  data.item.file != null &&
                  (computedCantExport || isCommerciaux)
              "
              size="sm"
              class="iconDownload mr-1"
              title="download facture"
              @click.prevent.stop="download(data.item)"
            >
              <font-awesome-icon icon="download" />
            </b-button>
            <b-button
              v-if="
                (data.item.statue == 'encore de paiement' ||
                  data.item.statue == 'facture valid' ||
                  data.item.statue == 'facture refuse' ||
                  data.item.statue == 'paye' ||
                  data.item.statue == 'encore de verification') &&
                  data.item.file != null &&
                  computedCantExport
              "
              size="sm"
              class="iconDownload"
              title="open facture"
              @click.prevent.stop="visualisation(data.item)"
            >
              <font-awesome-icon icon="eye" />
            </b-button>
          </template>
          <template v-slot:cell(appel_facturation)="data">
            <b-button
              v-if="computedCantExport || isCommerciaux"
              size="sm"
              class="iconDownload"
              title="télecharger rapport salaire"
              @click.prevent.stop="
                downloadAppelFacturation({ data: data.item, type: null })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(download_interne)="data">
            <b-button
              v-if="
                (computedCantExport || isCommerciaux) &&
                  data.item.interne != 0 &&
                  data.item.interne != 0.0
              "
              size="sm"
              class="iconDownload"
              title="télecharger rapport salaire"
              @click.prevent.stop="
                downloadAppelFacturation({ data: data.item, type: 'interne' })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(upload)="data">
            <div
              class="container"
              @drop="handleFileDrop($event, data.item)"
              v-if="
                data.item.statue !== 'paye' &&
                  data.item.statue !== 'encore de paiement' &&
                  data.item.statue != 'facture valid'
              "
            >
              <div class="file-wrapper" title="Upload facture">
                <input
                  type="file"
                  name="file-input"
                  accept="application/pdf,application/vnd.ms-excel"
                  @change="handleFileInput($event, data.item)"
                />
                <div class="upload_label" v-if="!data.item.file">
                  <font-awesome-icon icon="cloud-upload-alt" />
                </div>
                <div class="upload_label" v-if="data.item.file">
                  <font-awesome-icon
                    icon="file-pdf"
                    :pulse="getLoadingUploadFactureIndependantTh"
                  />
                </div>
              </div>
            </div>
          </template>

          <template #row-details="data">
            <template v-if="data.item.depots">
              <div
                class="body-task mb-2"
                v-for="(depotDetails, index) in data.item.depots"
                :key="'depot' + index"
              >
                <div class="align-td-item">
                  <td class="col-plus item-details-style mr-3 ">
                    <font-awesome-icon
                      class="caret-down ml-3 mr-5 "
                      :icon="
                        depotDetails.show == false
                          ? 'caret-right'
                          : 'caret-down'
                      "
                      @click="depotDetails.show = !depotDetails.show"
                    />
                  </td>
                  <td class="item-details-style justify-content-sb">
                    <div>
                      <b class="mr-2">Numéro de dépôt </b>
                      {{ depotDetails.name }}
                    </div>
                    <font-awesome-icon
                      icon="arrow-alt-circle-down"
                      class="icon ml-2"
                      @click="
                        downloadAppelFacturation({
                          data: data.item,
                          type: null,
                          depot: depotDetails.name
                        })
                      "
                    />
                  </td>

                  <td class="item-details-style justify-content-sb">
                    <div>
                      <b class="mr-2">Total </b>{{ depotDetails.total }} €
                    </div>
                  </td>
                  <td class="item-details-style justify-content-sb">
                    <div>
                      <b class="mr-2"> Acompte </b>
                      {{ depotDetails.acompt }} €
                    </div>
                  </td>
                  <template v-if="data.item.type == 'Commercial sedentaire'">
                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Non vente telepro : </b>
                        {{ depotDetails.interne }} €
                      </div>
                      <font-awesome-icon
                        icon="arrow-alt-circle-down"
                        class="icon ml-2"
                        @click="
                          downloadAppelFacturation({
                            data: data.item,
                            type: null,
                            depot: depotDetails.name,
                            type: 'interne'
                          })
                        "
                      />
                    </td>
                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Vente telepro</b
                        >{{ depotDetails.externe }} €
                      </div>
                    </td>
                  </template>
                  <template
                    v-if="
                      data.item.type == 'Pre visiteur' ||
                        data.item.type == 'Commercial terrain'
                    "
                  >
                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Vente</b> {{ depotDetails.vente }} €
                      </div>
                    </td>

                    <td class="item-details-style justify-content-sb">
                      <div>
                        <b class="mr-2">Previsite</b>
                        {{ depotDetails.previsite }} €
                      </div>
                    </td>
                  </template>
                </div>

                <template v-if="depotDetails.show && depotDetails.show == true">
                  <div class="details-num-dossier">
                    <div class="align-td-item">
                      <td class="item-details-style num-dossier">
                        <b>Numéro dossier</b>
                      </td>

                      <td class="item-details-style "><b>Nom </b></td>

                      <td class="item-details-style "><b>Prénom</b></td>
                      <td class="item-details-style "><b>Montant min</b></td>
                      <td class="item-details-style "><b>Acompte</b></td>
                    </div>
                    <div
                      class="align-td-item"
                      v-for="(task, indextask) in depotDetails.tasks"
                      :key="'depot' + depotDetails.name + 'tasks' + indextask"
                    >
                      <td class="item-details-style ">
                        {{ task.numero_dossier }}
                      </td>

                      <td class="item-details-style ">{{ task.nom }}</td>
                      <td class="item-details-style ">{{ task.prenom }}</td>

                      <td class="item-details-style ">
                        {{ task.montant_final }} €
                      </td>
                      <td class="item-details-style ">{{ task.acompt }} €</td>
                    </div>
                  </div>

                  <div
                    class="border-botom"
                    v-if="
                      data.item.depots && index < data.item.depots.length - 1
                    "
                  ></div>
                </template>

                <div
                  class="border-botom"
                  v-if="data.item.depots && index < data.item.depots.length - 1"
                ></div>
              </div>
            </template>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowUserSalaryIndependantTh"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditableInput from '../../component/EditableInput.vue';
import moment from 'moment';
export default {
  name: 'Validation-des-AAF-commerciaux',
  data() {
    return {
      filterUser: { full_name: 'tous', id: null },
      filterType: null,
      filteryear: null,
      filterDepot: null,
      filterValidation: null,
      filterMonth: null,
      ListYear: [],
      page: 1,
      perPage: 10,
      validationList: [
        { value: null, text: 'tous' },
        { value: 'en attend', text: 'En cours de validation' },
        { value: 'refuse', text: 'Réfusé' },
        { value: 'valid', text: 'appel à facturation validé' },
        { value: 'annuler', text: 'Annuler' },
        {
          value: 'encore de verification',
          text: 'En cours de vérification'
        },
        { value: 'facture valid', text: ' facture validé' },
        { value: 'facture refuse', text: ' facture réfusé' },

        { value: 'encore de paiement', text: 'En cours de paiement' },
        { value: 'paye', text: 'Payé' }
      ],
      fieldsPrévisiteur: [
        //prévisiteur && Commercial terrain

        { key: 'previsite', label: 'Prévisite' },
        { key: 'vente', label: 'Vente' },
        {
          key: 'total',
          label: 'Total en €'
        }
      ],
      fieldsSedentaire: [
        //Sédentaire && Confirmateur

        { key: 'externe', label: 'Vente telepro' },
        { key: 'interne', label: 'Non vente telepro' },
        {
          key: 'total',
          label: 'Total en €'
        }
      ],
      fieldsSticky: [
        { key: 'details', label: '', tdClass: 'color', thClass: 'th-color' },
        {
          key: 'name',
          label: 'Nom',
          thClass: 'th-name-fixed',
          tdClass: 'td-name-fixed'
        },
        { key: 'type', label: 'Type' },
        { key: 'month', label: 'Mois' },
        { key: 'acompt', label: 'Acompt' }
      ],
      fields: [
        // { key: 'variable_vmc', label: 'Variable Vmc' },
        // { key: 'variable_pac', label: 'Variable PAC' },
        // {
        //   key: 'IGD',
        //   label: 'IGD ',
        //   headerTitle: 'Indemnité Grand deplacement '
        // },
        // { key: 'prime_qualite', label: 'Prime Qualité' },
        // { key: 'prime_objectif', label: 'Prime Objectif' },
        // { key: 'prime_sav', label: 'Prime Sav' },
        // { key: 'primeE', label: 'Prime exceptionnelle' },
        // { key: 'other_prime_name', label: 'Autre Prime (Nom)' },
        // { key: 'other_prime_value', label: 'Autre Prime (Montant)' },
        { key: 'other_prime_value', label: 'Autres' },
        { key: 'deducation', label: 'Déducation' },
        { key: 'comment', label: 'Commentaire' },
        { key: 'appel_facturation', label: 'Appel à facturation' },
        // {
        //   key: 'download_interne',
        //   label: 'Appel à facturation in interne'
        // },
        { key: 'facture_uploaded', label: 'Facture' },
        { key: 'upload', label: 'Upload facture' },

        {
          key: 'payed_at',
          label: 'Date de paiement',
          thClass: 'thtotal',
          tdClass: 'tdtotal'
        },

        {
          key: 'statue',
          label: 'Statut',
          thClass: 'thvalidation',
          tdClass: 'tdvalidation'
        }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getSalaryIndependantFiltredTh',
      'newCommentTh',
      'validationSalaryTh',
      'downloadPDFIndependantUploadedTh',
      'getOnlineTypeTh',
      'getOnlineSalaryEmployeeBytypeTh',
      'updatePrimeTh',
      'downloadFactureCommerciauxPDF',
      'fetchMonthForYearTH',
      'featchDepotForYearTH',
      'uploadFactureAafIndependantTh',
      'downloadSalaryValidationPDFTh'
    ]),
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.statue == 'refuse') return 'ligneRefuse';
      else if (item.statue == 'valid') return 'ligneAccepted';
      else if (item.statue == 'annuler') return 'ligneAnnuler';
      else if (item.statue == 'encore de verification')
        return 'ligneVerification';
      else if (item.statue == 'facture valid') return 'ligneValidF';
      else if (item.statue == 'facture refuse') return 'ligneRefuseF';
      else if (item.statue == 'encore de paiement') return 'ligneEnAttend';
      else if (item.statue == 'paye') return 'lignePaye';
      else return 'ligneEnCourDeValidation';
    },
    hendleGetTypeAndUser() {
      this.getOnlineTypeTh({
        year: this.filteryear,
        salarie: 'non',
        month: this.filterMonth
      });
      this.getOnlineSalaryEmployeeBytypeTh({
        year: this.filteryear,
        type: this.filterType,
        salarie: 'non',
        month: this.filterMonth
      });
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      sessionStorage.setItem(
        'page-validation-salaire-commerciaux-th',
        this.page
      );
      this.setLocalStorge();
      this.hendleGetTypeAndUser();
      this.filterTable();
    },
    handleChangeDepot() {
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      this.hendleGetTypeAndUser();
      this.filterTable();
    },
    handleChangeYear() {
      this.filterUser = { full_name: 'tous', id: null };
      this.filterType = null;
      this.setLocalStorge();
      this.page = 1;
      sessionStorage.setItem(
        'page-validation-salaire-commerciaux-th',
        this.page
      );
      this.fetchMonthForYearTH(this.filteryear);
      this.featchDepotForYearTH(this.filteryear);
      this.hendleGetTypeAndUser();

      this.filterTable();
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterType = null;
      this.filterUser = { full_name: 'tous', id: null };
      this.hendleGetTypeAndUser();
      this.filterTable();
    },
    handleChangeType() {
      this.page = 1;
      this.filterUser = { full_name: 'tous', id: null };
      sessionStorage.setItem(
        'page-validation-salaire-commerciaux-th',
        this.page
      );
      this.setLocalStorge();
      this.getOnlineSalaryEmployeeBytypeTh({
        year: this.filteryear,
        type: this.filterType,
        salarie: 'non',
        month: this.filterMonth
      });
      this.filterTable();
    },
    setLocalStorge() {
      localStorage.setItem(
        'Validation-salaire-Filter-Commerciaux-th',
        JSON.stringify({
          filteryear: this.filteryear,
          filterDepot: this.filterDepot,
          filterUser: this.filterUser,
          filterValidation: this.filterValidation,
          filterType: this.filterType
        })
      );
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem(
        'page-validation-salaire-commerciaux-th',
        this.page
      );
      this.filterTable();
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem(
        'page-validation-salaire-commerciaux-th',
        this.page
      );
      this.filterTable();
    },
    download(item) {
      this.downloadPDFIndependantUploadedTh({
        item
      });
    },
    visualisation(item) {
      window.open(item.file.path, '_blank');
    },
    downloadAppelFacturation(item) {
      this.downloadSalaryValidationPDFTh({
        item: item.data,
        year: this.filteryear,
        type: item.type,
        depot: item.depot ? item.depot : null
      });
    },
    filterTable() {
      this.getSalaryIndependantFiltredTh({
        depot: this.filterDepot,
        employee_id: this.filterUser,
        year: this.filteryear,
        page: this.page,
        validation: this.filterValidation,
        type: this.filterType,
        perPage: this.perPage,
        month: this.filterMonth
      });
    },
    handleFileDrop(e, item) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        item.file = f;
      });
      this.uploadFactureAafIndependantTh(item);
    },
    async handleFileInput(e, item) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        item.file = f;
      });
      const responce = await this.uploadFactureAafIndependantTh(item);
      if (responce) {
        this.$alert('', 'facture uploadé', 'success');
      } else {
        this.$alert(this.getErrorUploadFactureIndependantTh, '', 'error');
      }
    }
  },
  computed: {
    ...mapGetters([
      'getAllEmployee',
      'getChargementPaieIndependantTh',
      'getTotalRowUserSalaryIndependantTh',
      'getUserSalaryIndependantTh',
      'getDepots',
      'getOnlineTypeEmloyeesTh',
      'getActiveEmployyesTh',
      'getUserData',
      'getMissigSemaineIndependant',
      'checkPermission',
      'checkPermission',
      'checkPermission',
      'getMonthForYearTh',
      'getAllDepotForYearTh',
      'getErrorUploadFactureIndependantTh',
      'getLoadingUploadFactureIndependantTh'
    ]),
    computeAdmin: function() {
      return this.getUserData && this.getUserData.role != 'admin';
    },
    computedTypeValidation: function() {
      return function(data) {
        if (
          data.type == 'Responsable planning' ||
          data.type == 'Admin GE' ||
          data.statue == 'valid'
        ) {
          return false;
        }
        return true;
      };
    },
    computedDepot() {
      let tab = [];
      if (this.filterMonth == null) {
        tab = this.getAllDepotForYearTh.map(item => ({
          value: item.depot,
          text: item.depot
        }));
        return tab;
      } else {
        let table = this.getMonthForYearTh.filter(
          item => item.month_name == this.filterMonth
        );
        if (table.length) {
          tab = table[0].depots.map(item => ({
            value: item.depot,
            text: item.depot
          }));
          return tab;
        } else {
          return [];
        }
      }
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedCantExport() {
      return this.checkPermission(this.$options.name);
    },
    ComputedgetAllType() {
      return this.getOnlineTypeEmloyeesTh.map(item => {
        return {
          value: item,
          text: item
        };
      });
    },
    ComputedgetAllEmployee() {
      return [{ id: null, full_name: 'tous' }, ...this.getActiveEmployyesTh];
    },
    ComputedgetAllDepot() {
      return [{ id: null, name: 'tous' }, ...this.getDepots];
    },
    computedgetuserSalary() {
      if (this.filterValidation != null) {
        return this.getUserSalaryIndependantTh.filter(
          item => item.statue == this.filterValidation
        );
      }
      return this.getUserSalaryIndependantTh;
    },

    computedColor() {
      return function(item) {
        if (item.validation == 'refuse') return 'red';
        else if (item.validation == 'valid') return 'ligneAlert';
        else return 'ligneNormale';
      };
    },
    computeReponsableDepot: function() {
      return this.getUserData && this.getUserData.role === 'responsable_depot';
    },
    computedValidationList() {
      return [
        {
          value: 'en attend',
          full_name: 'En cours de validation',
          $isDisabled: true
        },
        { value: 'refuse', full_name: 'Réfusé' },
        { value: 'annuler', full_name: 'Annuler' },
        { value: 'valid', full_name: 'Validé lappel a facturation' },
        {
          value: 'encore de verification',
          full_name: 'En cours de vérification'
        },
        { value: 'facture valid', full_name: 'Validé la facture' },
        { value: 'facture refuse', full_name: 'Réfusé la facture' },
        { value: 'encore de paiement', full_name: 'En cours de paiement' },

        { value: 'paye', full_name: 'Payé' }
      ];
    },
    computedFiled() {
      let FieldNet = [];

      if (this.getUserData && this.getUserData.role == 'admin') {
        FieldNet = this.fields.filter(item => item.key != 'upload');
      } else {
        FieldNet = this.fields;
      }
      if (this.filterType == null) {
        let fieldsNew = this.fieldsSedentaire.filter(
          item => item.key != 'total'
        );
        return [
          ...this.fieldsSticky,
          ...fieldsNew,
          ...this.fieldsPrévisiteur,
          ...FieldNet
        ];
      }
      if (
        this.filterType == 'Pre visiteur' ||
        this.filterType == 'Commercial terrain'
      ) {
        return [...this.fieldsSticky, ...this.fieldsPrévisiteur, ...FieldNet];
      }
      if (this.filterType == 'Commercial sedentaire') {
        return [...this.fieldsSticky, ...this.fieldsSedentaire, ...FieldNet];
      }
      let fieldsNew = this.fieldsSedentaire.filter(item => item.key != 'total');
      return [
        ...this.fieldsSticky,
        ...fieldsNew,
        ...this.fieldsPrévisiteur,
        ...FieldNet
      ];
    },
    ComputedgetMissigSemaineIndependant() {
      if (this.getMissigSemaineIndependant != null) {
        const table = Object.fromEntries(
          Object.entries(this.getMissigSemaineIndependant).filter(
            ([key, value]) => {
              return key && value == false;
            }
          )
        );
        return Object.keys(table);
      }
      return this.getMissigSemaineIndependant;
    },
    isCommerciaux() {
      return (
        this.getUserData &&
        (this.getUserData.role === 'Responsable planning' ||
          this.getUserData.role === 'Commercial sedentaire' ||
          this.getUserData.role === 'Admin GE' ||
          this.getUserData.role === 'Commercial terrain' ||
          this.getUserData.role === 'Pre visiteur')
      );
    }
  },
  components: {
    commentInput: () => import('../../component/CommentInput.vue'),
    SelectComponent: () => import('../../SelectComponent.vue'),
    palatteColor: () => import('../../component/paletteColor.vue'),
    EditableInput
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('Validation-salaire-Filter-Commerciaux-th')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux-th')
      ).filteryear;
      this.filterDepot = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux-th')
      ).filterDepot;
      this.filterUser = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux-th')
      ).filterUser;
      this.filterValidation = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux-th')
      ).filterValidation;
      this.filterType = JSON.parse(
        localStorage.getItem('Validation-salaire-Filter-Commerciaux-th')
      ).filterType;
    } else {
      this.filteryear = courantYear;
      this.setLocalStorge();
    }
    if (sessionStorage.getItem('page-validation-salaire-commerciaux-th')) {
      this.page = sessionStorage.getItem(
        'page-validation-salaire-commerciaux-th'
      );
    } else {
      sessionStorage.setItem(
        'page-validation-salaire-commerciaux-th',
        this.page
      );
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.fetchMonthForYearTH(this.filteryear);
    this.featchDepotForYearTH(this.filteryear);
    this.hendleGetTypeAndUser();
    this.filterTable();
  }
};
</script>

<style scoped lang="scss">
.tableValidationCommerciauxTh {
  height: calc(100vh - 232px);
  max-height: calc(100vh - 151px);
  margin-bottom: 0px;
  .body-task {
    // display: flex;
    margin-left: 26px;
    width: 1156px;
    position: sticky;
    left: 5px;

    span {
      text-align: start;
      min-width: 172px;
    }
    .span_num {
      width: 20%;
    }

    .details-num-dossier {
      border: 1px solid #f6f5fb;
      width: 782px;
      margin-top: 0px;
      margin-bottom: 10px;
      margin-left: 3%;
      background-color: #f0f1ff;
      .num-dossier {
        border-bottom: 1px solid #f6f5fb;
        border-right: 1px solid #f6f5fb;
      }
    }
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .caret-down {
    font-size: 16px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .container {
    height: 28px;
    width: 28px;
    padding: 2px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px solid #6c757d;
      border-radius: 50%;
      color: #6c757d;
      background-color: transparent;
      &:hover {
        background-color: #6c757d;
        border-color: white;
        color: white;
      }
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 14px;
        p {
          font-size: 9px;
        }
      }
    }
  }
}
.body-box-rapport {
  height: calc(100vh - 106px);
}
.resizing-body-box-rappot {
  .tableValidationCommerciauxTh {
    max-height: calc(100vh - 223px) !important;
    height: calc(100vh - 223px) !important;
  }
}
.sub-menu .body .body-box-rapport {
  height: calc(100vh - 175px);
}
.List {
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.iconDownload {
  padding: 1px 4px;
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
  &:hover {
    background-color: #6c757d;
    border-color: white;
    color: white;
  }
}

.contentValidationCommerciauxTh {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
</style>
